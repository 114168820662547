import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapComponent } from './components/map/map.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BaseFloatComponent } from './components/map/layer-switcher/base-float/base-float.component';

import { MatSelectModule } from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSidenavModule} from '@angular/material/sidenav'
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card'

import { ErrorComponent } from './components/shared/snackbar/error/error.component';
import { ImportshapeComponent } from './components/map/tools/importshape/importshape.component';
import { SuccessComponent } from './components/shared/snackbar/success/success.component';
import { ImportShapeFileDialogComponent } from './components/shared/dialogs/import-shape-file-dialog/import-shape-file-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


import { SidebarComponent, SidebarPaneDirective, SidebarTabDirective,SidebarTabs,SidebarPanes } from './components/map/sidebar/sidebar.component';
import { NavComponent } from './components/main/nav/nav.component';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { CoupesComponent } from './components/main/coupes/coupes.component';
import { DashboardComponent } from './components/main/dashboard/dashboard.component';
import { DevicesComponent } from './components/main/devices/devices.component';
import { AddDeviceDialogComponent } from './components/shared/dialogs/add-device-dialog/add-device-dialog.component';
import { CompaniesComponent } from './components/main/companies/companies.component';
import { UsersComponent } from './components/main/users/users.component';
import { AddCompanyDialogComponent } from './components/shared/dialogs/add-company-dialog/add-company-dialog.component';
import { AddUserDialogComponent } from './components/shared/dialogs/add-user-dialog/add-user-dialog.component';
import { EditUserComponent } from './components/shared/dialogs/user/edit-user/edit-user.component';
import { DeleteUserComponent } from './components/shared/dialogs/user/delete-user/delete-user.component';
import { EditDeviceComponent } from './components/shared/dialogs/device/edit-device/edit-device.component';
import { DeleteDeviceComponent } from './components/shared/dialogs/device/delete-device/delete-device.component';
import { EditCompanyComponent } from './components/shared/dialogs/company/edit-company/edit-company.component';
import { DeleteCompanyComponent } from './components/shared/dialogs/company/delete-company/delete-company.component';
import { AttributeOverlayComponent } from './components/map/tools/attribute-overlay/attribute-overlay.component';
import { SpatialReferenceSelectorComponent } from './components/shared/dialogs/spatial-reference-selector/spatial-reference-selector.component';
import { AuthGuard } from './guards/auth.guard';
import { RoleGuard } from './guards/role.guard';
import { AuthService } from './services/auth.service';
import { spatialService } from './services/spatial.service';
import { authInterceptorProviders } from './interceptors/auth.interceptor';
import { CsvUploadComponent } from './components/map/tools/csv-upload/csv-upload.component';
import { FilterComponent } from './components/map/tools/filter/filter.component';
import { ResetPasswordAdminComponent } from './components/shared/dialogs/user/reset-password-admin/reset-password-admin.component';
import { EditCoupeComponent } from './components/shared/dialogs/coupe/edit-coupe/edit-coupe.component';
import { DeleteCoupeComponent } from './components/shared/dialogs/coupe/delete-coupe/delete-coupe.component';
import { CoupeCardComponent } from './components/map/coupe-card/coupe-card.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TreeExportComponent } from './components/map/tools/tree-export/tree-export.component';
import { DeviceNotFoundComponent } from './components/shared/dialogs/device-not-found/device-not-found.component';
import { RestoreConflictedTreePointDialogComponent } from './components/shared/dialogs/restore-conflicted-tree-point-dialog/restore-conflicted-tree-point-dialog.component';
import { LayerComponent } from './components/map/layer-switcher/layer/layer.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    BaseFloatComponent,
    ErrorComponent,
    ImportshapeComponent,
    SuccessComponent,
    ImportShapeFileDialogComponent,
    SidebarComponent,
    SidebarTabDirective,
    SidebarPaneDirective,
    SidebarTabs,
    SidebarPanes,
    NavComponent,
    LoginComponent,
    MainComponent,
    CoupesComponent,
    DashboardComponent,
    DevicesComponent,
    AddDeviceDialogComponent,
    CompaniesComponent,
    UsersComponent,
    AddCompanyDialogComponent,
    AddUserDialogComponent,
    EditUserComponent,
    DeleteUserComponent,
    EditDeviceComponent,
    DeleteDeviceComponent,
    EditCompanyComponent,
    DeleteCompanyComponent,
    AttributeOverlayComponent,
    SpatialReferenceSelectorComponent,
    CsvUploadComponent,
    FilterComponent,
    ResetPasswordAdminComponent,
    EditCoupeComponent,
    DeleteCoupeComponent,
    CoupeCardComponent,
    TreeExportComponent,
    DeviceNotFoundComponent,
    RestoreConflictedTreePointDialogComponent,
    LayerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule
  ],
  providers: [AuthGuard, RoleGuard, authInterceptorProviders, AuthService, spatialService],
  bootstrap: [AppComponent]
})
export class AppModule { }
