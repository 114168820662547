import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { combineLatest, map } from 'rxjs';
import { Coupe } from 'src/app/components/main/coupes/coupes.component';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { Company, CompanyService } from 'src/app/services/company.service';
import { CoupeService } from 'src/app/services/coupe.service';
import { Device, DeviceService } from 'src/app/services/device.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {

  filterForm: UntypedFormGroup;

  @Output() filterChanged = new EventEmitter<String>(null);

  vm$ = combineLatest([
    this.deviceService.getAll(),
    this.companyService.companies,
    this.coupeService.getAll()
  ]).pipe(
    map(([devices, companies, coupes,]) => ({
      devices,
      companies,
      coupes,
    }))
  )

  user: User = null;

  constructor(public deviceService: DeviceService, public companyService: CompanyService, public coupeService: CoupeService, public authService: AuthService) {

authService.user.subscribe(user => {
  this.user = user;
})
  }

  ngOnInit(): void {

    this.filterForm = new UntypedFormGroup({
      'coupes': new UntypedFormControl(null),
      'devices': new UntypedFormControl(null),
      'companies': new UntypedFormControl(),
  });

  this.filterForm.get('companies').patchValue([this.user.companyID]);

  this.buildFilter();

  }

  buildFilter()
  {
    let filter = '';


    filter += this.buildDeviceFilter();

    filter += `${this.buildCompaniesFilter()}`;

    filter += this.buildCoupesFilter();

    filter = filter.endsWith('&') ? filter.slice(0, -1) : filter;

    filter = filter === '' ? '' : '&CQL_FILTER=' + filter;

    this.filterChanged.emit(filter);
  }

  resetForm()
  {
    this.filterForm.reset();
  }

  private buildDeviceFilter()
  {
   let devices = this.filterForm.get('devices').value as Array<Number>

    if(devices == null || devices.length == 0)
    {
      return '';
    }

   return `DeviceID%20IN%20(${devices.join(',')})&`;

  }

  private buildCompaniesFilter()
  {
    let companies = this.filterForm.get('companies').value as Array<Number>

    if(companies == null || companies.length == 0)
    {
      return '';
    }

   return `CompanyID%20IN%20(${companies.join(',')})&`;
  }

  private buildCoupesFilter()
  {
    let coupes = this.filterForm.get('coupes').value as Array<Number>

    if(coupes == null || coupes.length == 0)
    {
      return '';
    }

   return `CoupeID%20IN%20(${coupes.join(',')})&`;
  }

}
