import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { defaults as defaultInteractions, DragBox } from 'ol/interaction';
import { platformModifierKeyOnly } from 'ol/events/condition';
import { transformExtent } from 'ol/proj';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-area-of-interest-dialog',
  templateUrl: './area-of-interest-dialog.component.html',
  styleUrls: ['./area-of-interest-dialog.component.css']
})
export class AreaOfInterestDialogComponent implements OnInit, AfterViewInit {

  @ViewChild('map', { static: true }) mapElementRef: ElementRef;

  private map: Map;

  public form = new FormGroup({
    minLon: new FormControl(),
    minLat: new FormControl(),
    maxLon: new FormControl(),
    maxLat: new FormControl()
  });

  constructor() { }

  ngOnInit(): void {


  }


  ngAfterViewInit() {
    const dragBox = new DragBox({
      condition: platformModifierKeyOnly
    });

    this.map = new Map({
      interactions: defaultInteractions().extend([dragBox]),
      layers: [
        new TileLayer({
          source: new OSM()
        })
      ],
      target: this.mapElementRef.nativeElement,
      view: new View({
        center: [0, 0],
        zoom: 2
      })
    });

    dragBox.on('boxend',  () =>  {
      // coordinates of the selected area
      const extent = dragBox.getGeometry().getExtent();
      let boundingBoxIn4326 = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');

      this.form.get("minLon").patchValue(boundingBoxIn4326[0]);
      this.form.get("minLat").patchValue(boundingBoxIn4326[1]);
      this.form.get("maxLon").patchValue(boundingBoxIn4326[2]);
      this.form.get("maxLat").patchValue(boundingBoxIn4326[3]);
      this.map.getView().fit(extent);
      // you may want to send the extent back to your main component here
    });

    this.map.updateSize();

  }

  onSubmit() {
    const values = this.form.value;
    console.log(values);
    // Here you would update the map based on the entered coordinates
  }

}
