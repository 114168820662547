import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AreaOfInterestDialogComponent } from './components/area-of-interest-dialog/area-of-interest-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    AreaOfInterestDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatSliderModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatRadioModule,
    MatTabsModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatCheckboxModule,
    MatCardModule,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule
  ],
  exports:[
    AreaOfInterestDialogComponent,
    MatDialogModule,
    MatSliderModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatRadioModule,
    MatTabsModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatCheckboxModule,
    MatCardModule,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule
  ]
})
export class SharedModule { }
