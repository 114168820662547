<div #mapElementRef id="map" class="map"></div>

<app-importshape [map]="spatialService.map" (features)="shapeFileImported($event)"></app-importshape>

<div *ngIf="!production" class="test-system"><span>Test System</span></div>

<div #addAttributeOverlayHere></div>
<app-sidebar>
  <sidebar-tabs>
    <ul role="tablist">
      <li>
        <a type="button" role="tab" [routerLink]="[ '',{ outlets: {primary:null, aux: ['dashboard'] } }]"
         class="sidebar_tooltip">
          <i class="fa fa-home"></i
          ><span class="sidebar_tooltiptext">Home</span></a
        >
      </li>
      <li sidebarTab sidebarTargetPane="layers">
        <a type="button" role="tab" class="sidebar_tooltip">
          <i class="fa fa-layer-group"></i
          ><span class="sidebar_tooltiptext">Layers</span></a
        >
      </li>
      <li sidebarTab startOpen="true" sidebarTargetPane="coupes">
        <a type="button" role="tab" class="sidebar_tooltip">
          <i class="fa fa-vector-square"></i
          ><span class="sidebar_tooltiptext">Coupes</span></a
        >
      </li>
      <li sidebarTab sidebarTargetPane="test">
        <a  type="button" role="tab" class="sidebar_tooltip">
          <i class="fa fa-filter"></i
          ><span class="sidebar_tooltiptext">Filter Trees</span></a
        >
      </li>
      <li sidebarTab sidebarTargetPane="upload">
        <a  type="button" href="#ol-display-props" role="tab" class="sidebar_tooltip">
          <i class="fa fa-upload"></i
          ><span class="sidebar_tooltiptext">Upload</span></a
        >
      </li>
      <li sidebarTab sidebarTargetPane="exportTrees">
        <a  type="button" href="#ol-display-props" role="tab" class="sidebar_tooltip">
          <i class="fa fa-chart-bar"></i
          ><span class="sidebar_tooltiptext">Export Trees</span></a
        >
      </li>

    </ul>

    <ul role="tablist">

    </ul>
  </sidebar-tabs>

  <sidebar-panes>

    <section sidebarPane sidebarPaneName="layers">
      <header>
        <h2>Layers</h2>
      </header>
      <main class="flex flex--column grid--gap-sm overflow-y--scroll">
      </main>
    </section>

    <section sidebarPane sidebarPaneName="coupes">
      <header>
        <h2>Coupes</h2>
      </header>
      <main class="flex flex--column grid--gap-sm overflow-y--scroll">
        <mat-form-field appearance="outline">
          <mat-label>Filter Coupes</mat-label>
          <input [formControl]="searchField" debounce="100" matInput>
        </mat-form-field>

        <ng-container *ngIf="{coupes:filteredCoupes$ | async } as vm">
          <app-coupe-card *ngFor="let coupe of vm.coupes" [coupe]="coupe" (zoomTo)="zoomToCoupe($event)" (update)="updateCoupe($event)" (mouseenter)="hoverCoupe(coupe)" (mouseleave)="unHoverCoupe(coupe)">

          </app-coupe-card>
          <mat-card *ngIf="!vm.coupes" >
            <mat-card-title><ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader></mat-card-title>
            <mat-card-subtitle ><ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader></mat-card-subtitle>
            <mat-card-actions align="end" class="grid grid--colum grid--gap-sm" >
              <ngx-skeleton-loader count="1" appearance="circle"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" appearance="circle"></ngx-skeleton-loader>
            </mat-card-actions>
          </mat-card>


        </ng-container>


      </main>
    </section>

    <section sidebarPane sidebarPaneName="test">
      <header>
        <h2>Filter Trees</h2>
      </header>
      <main>
       <app-filter (filterChanged)="applyFilter($event)"></app-filter>
  <button (click)="test()">test</button>
      </main>
      <footer>

      </footer>
    </section>

    <section sidebarPane sidebarPaneName="upload">
      <header>
        <h2>Import</h2>
      </header>
      <main>
       <app-csv-upload (response)="treesImported($event)"></app-csv-upload>
      </main>
      <footer>

      </footer>
    </section>

    <section sidebarPane sidebarPaneName="exportTrees">
      <header>
        <h2>Export Trees</h2>
      </header>
      <main>
        <app-tree-export></app-tree-export>
      </main>
      <footer>

      </footer>
    </section>

  </sidebar-panes>
</app-sidebar>
