import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throws } from 'assert';
import  * as jsZip from 'jszip';
import { filter, flatMap, map, Subscription } from 'rxjs';
import { AddDeviceDialogComponent } from 'src/app/components/shared/dialogs/add-device-dialog/add-device-dialog.component';
import { DeviceNotFoundComponent } from 'src/app/components/shared/dialogs/device-not-found/device-not-found.component';
import { ErrorComponent } from 'src/app/components/shared/snackbar/error/error.component';
import { SuccessComponent } from 'src/app/components/shared/snackbar/success/success.component';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { Company, CompanyService } from 'src/app/services/company.service';
import { DeviceService } from 'src/app/services/device.service';
import { ImportResponse, ImportService } from 'src/app/services/import.service';

@Component({
  selector: 'app-csv-upload',
  templateUrl: './csv-upload.component.html',
  styleUrls: ['./csv-upload.component.css']
})
export class CsvUploadComponent implements OnInit {


  @Output() response = new EventEmitter<ImportResponse>(null);

  file:File = null;
  size: string;
  uploading:boolean = false;
  data: ImportResponse = null;
  companySub$: Subscription;

  companies: Array<Company>;

  selectedCompany = null;

  filteredCompanySub;

  user: User;

  constructor(private elRef:ElementRef, private importService: ImportService, private snackBar:MatSnackBar, private companyService: CompanyService, private authService:AuthService, private dialog:MatDialog, private deviceService: DeviceService) {

    this.filteredCompanySub = companyService.companies.pipe(map(companies => companies.filter(company => company.type.toLowerCase() == "grower")))

  authService.user.subscribe(user => {
    this.user = user;
  })
   }

  ngOnInit(): void {
    let element = this.elRef.nativeElement as HTMLElement;

    element.ondragover = (event) => {
      event.preventDefault();
    }
    element.ondrop = (event) => {
      event.preventDefault();
    }
  }

  fileSelected(event:Array<File>|FileList)
  {
    this.file = event[0];
    this.size = this.formatSize(this.file)
  }

  async uploadFile()
  {
    this.uploading = true;

    let data = new FormData();
    if(this.selectedCompany != null)
    {
      data.append('companyID', this.selectedCompany.id.toString());
    }

    if(this.file.type == 'application/x-zip-compressed')
    {
      data.append('file',this.file);
    }
    else
    {
      var zip = new jsZip();

      let csvData = await this.file.arrayBuffer();

      zip.file(`${this.file.name}`,csvData);

      let compressedFile = await zip.generateAsync({type:"blob"});

      data.append('file',compressedFile);
    }


    this.importService.import(data).subscribe( {
      next: (data) => {
        this.response.emit(data);
        this.data = data;
         this.uploading = false;
         this.file = null;
      },
      error: (error) => {
        this.uploading = false;
        if(error.message.includes('DEVICE_NOT_IN_SYSTEM'))
        {

          let data = error.message as string;

          data = data.split(':')[1];

          const dialogRef = this.dialog.open(DeviceNotFoundComponent, {
            hasBackdrop: true,
            width: '300px',
            data:data
          });

          dialogRef.afterClosed().subscribe(async (result) => {
            if(result)
            {
              const dialogRef = this.dialog.open(AddDeviceDialogComponent, {
                hasBackdrop: true,
                width: '300px',
                data:data
              });

              dialogRef.afterClosed().subscribe(async (result) => {
                this.deviceService.add(result).subscribe(response => {
                  this.snackBar.openFromComponent(SuccessComponent, {
                    duration: 20 * 1000,
                    data: 'Device added',
                    panelClass: ['success-snackbar']
                  });

              });
              }
      );
          }});
    }
  }
});
  }

  formatSize(file:File)
  {
    return this.file.size >= 1000000 ? `${(this.file.size / 1000000).toFixed(2)}MB` : `${(this.file.size / 100000).toFixed(2)}KB`;
  }

  dragover(event:Event)
  {
    event.preventDefault();
  }

  onDrop(event:DragEvent)
  {
    event.preventDefault();
    this.fileSelected(event.dataTransfer.files)
  }

  showSuccess(message:string): void {
    this.snackBar.openFromComponent(SuccessComponent, {
      duration: 20 * 1000,
      data: message,
      panelClass: ['success-snackbar']
    });
  }

  showError(message:string):void{
    this.snackBar.openFromComponent(ErrorComponent, {
      duration: 20 * 1000,
      data: message,
      panelClass: ['error-snackbar']
    });
  }

}
