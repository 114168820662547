import OlMap from 'ol/Map';
import View from 'ol/View';
import ScaleLine from 'ol/control/ScaleLine';
import Attribution from 'ol/control/Attribution';
import * as olProj from 'ol/proj';
import { defaults as defaultInteractions, PinchZoom } from 'ol/interaction';
import { Injectable, NgZone } from '@angular/core';
import SidebarEsk, { SidebarEsk as Sidebar } from '../_helpers/sidebar_esk';
import LayerGroup from 'ol/layer/Group';
import { populateLayerGroup } from '../_helpers/LayerBuilder';
import { mapProj3857, mapProjEPSG } from '../_helpers/projection_setup';
import EsriJSON from 'ol/format/EsriJSON';
import XYZ from 'ol/source/XYZ';
import { createXYZ } from 'ol/tilegrid';
import { base_map_defs, defaultLayers } from '../_helpers/basemaps';
import { Fill, Stroke, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { tile as tileStrategy } from 'ol/loadingstrategy';


import { Collection, Feature } from 'ol';
import { BehaviorSubject, lastValueFrom, zip } from 'rxjs';
import {
  Buffer,
  ConvertFeatureToGeoJson,
  ConvertGeoJsonToFeature,
  ConvertGeoJsonToFeatureCollection,
  ConvertFeatureCollectionToGeoJson,
  Union,
  Intersect,
  CloneProperties,
  CalculateArea,
  Difference,
  Intersects,
  CalculateLength,
} from '../_helpers/transformations';

import VectorImageLayer from 'ol/layer/VectorImage';

import VectorTileLayer from 'ol/layer/VectorTile';
import { BingMaps, DataTile } from 'ol/source';
import GeoJSON from 'ol/format/GeoJSON';
import {bbox as bboxStrategy} from 'ol/loadingstrategy';
import { style } from '@angular/animations';
import { ProjectionLike } from 'ol/proj';


import { feature } from '@turf/turf';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorComponent } from '../components/shared/snackbar/error/error.component';
import { AuthService } from './auth.service';
import { User } from '../models/user.model';
import { getCoupeStyle } from '../components/map/helpers/layer.styles';
import { environment } from 'src/environments/environment';
import TileLayer from 'ol/layer/Tile';






@Injectable({
  providedIn: 'root',
})
export class spatialService {

  map: OlMap;

  proj : ProjectionLike | null = olProj.get(mapProj3857);

  esrijsonFormat = new EsriJSON();

  propAliasMap: Map<string, string> = new Map();

  basemapGroup;

  layerSwitcher;


  coupesSource: VectorSource<any>;


  user: User;

  sidebar: SidebarEsk;

  constructor(
    private zone: NgZone,
    private snackBar: MatSnackBar,
    private authService: AuthService
  ) {

    this.basemapGroup = new LayerGroup({
      layers: [],
      title: 'Background Maps',
      fold: 'open',
    } as any);


    var bingBaseMap =  new TileLayer({
      source:
      new BingMaps({
      key: "ApjDwuDN5ZojO5zSMU_CqVhOaB5OTRFp0uzIS2IYfHJDT1BTrfY8Lt6HvIQ30K1C",
      imagerySet: "Aerial"
    })});


    this.authService.user.subscribe(user=> {
      this.user = user
    })

    this.zone.runOutsideAngular(() => {
      this.map = new OlMap({
        layers: [
          bingBaseMap,
        ],
        view: new View({
          center: [494095, 5343594],
          projection: this.proj == null ? null : this.proj,
          zoom: 5,
          maxZoom: 23,
        }),
        pixelRatio: window.devicePixelRatio,
        controls: [
          new Attribution(),
          new ScaleLine({
            bar: true,
            minWidth: 150,
          }),
        ],
      });
  });


  this.setupBaseMaps();

  }



  addCoupesLayer()
  {
     this.coupesSource = new VectorSource({
      loader: async (extent, resolution, projection, success, failure) => {
        let encodedComponent = encodeURIComponent(
          '{"xmin":' +
            extent[0] +
            ',"ymin":' +
            extent[1] +
            ',"xmax":' +
            extent[2] +
            ',"ymax":' +
            extent[3] +
            ',"spatialReference":{"wkid":3857}}'
        );

const url = `${environment.geoServerURL}OFO-GeoTree/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=cite%3ACoupes&srsname=EPSG:3857&outputFormat=application%2Fjson`;

        try {
          let response = await fetch(url);

          if (response.ok) {
            let featuresJson = await response.json();



            const features = ConvertGeoJsonToFeatureCollection(featuresJson);

            if (features.length > 0) {
              features.forEach((element) => {
                let props = element.getProperties();
                element.setId(props.OBJECTID);
              });

              this.coupesSource.clear();
              this.coupesSource.addFeatures(features);
            }

            success(features);
          }
        } catch (error) {
          failure();
        }
      },
      strategy: tileStrategy(
        createXYZ({
          tileSize: 1028,
        })
      ),
    });

    let r = new VectorImageLayer({
      imageRatio: 2,
      source: this.coupesSource,
      style: (feature, resolution) => {
        return getCoupeStyle(feature,resolution,'');
      }
    });

    this.map.addLayer(r);
  }

  /**
   * Sets the view to the accordant zoom and center.
   *
   * @param zoom Zoom.
   * @param center Center in long/lat.
   */
  setView(zoom: number, center: [number, number]) {
    //this.map.getView().setZoom(10);
  }

  /**
   * Updates target and size of the map.
   *
   * @param target HTML container.
   */
  updateSize(target = 'map') {
    this.map.setTarget(target);
    this.map.updateSize();
  }

  addDefaultControls(target = 'ol-toolbar') {
    //this.map.addControl(EskZoomTool(target));
  }

  addDefaultInteractions() {
    this.map.addInteraction(new PinchZoom());
    let _defaultInteractions = defaultInteractions();
    _defaultInteractions.forEach((interaction) => {
      this.map.addInteraction(interaction);
    });
  }

  addSideBar(target = 'sidebar_div') {
    this.zone.runOutsideAngular( ()=>{
      this.sidebar = new Sidebar({ target: target, position: 'right' });
      this.sidebar.setOpenCallback(this.sidebarToggleOpen);
      this.sidebar.setCloseCallback(this.sidebarToggleClose);
      this.map.addControl(this.sidebar);
    })

    return this.sidebar;
  }


  removeControls() {
    let interactions = this.map.getInteractions();

    let layers = this.map.getAllLayers();
    layers.forEach((layer) => {
      this.map.removeLayer(layer);
    });

    interactions.forEach((interaction) => {
      this.map.removeInteraction(interaction);
    });

    let controls = this.map.getControls();

    controls.forEach((control) => {
      this.map.removeControl(control);
    });

  }


  sidebarToggleOpen(openID: string, closeID: string) {}

  sidebarToggleClose() {}

  /**
   * "If the property is not in the map, add it to the map."
   *
   * The function is called in the following code:
   * @param feature - The feature object that contains the attributes.
   */
  private updateAttributeMap(feature) {
    for (const property in feature.fieldAliases) {
      if (this.propAliasMap.has(property)) continue;

      this.propAliasMap.set(property, feature.fieldAliases[property]);
    }
  }

  /**
   * When the user clicks on a feature, zoom to the feature's geometry.
   * @param feature - Feature<any>
   */
  zoomToFeature(feature: Feature<any>) {
    if (feature == null) {
      return;
    }
    let polygon = feature.getGeometry();

    this.map.getView().fit(polygon, { padding: [150, 450, 150, 0] });
  }

  setupBaseMaps() {
    if (this.basemapGroup.values_.layers.array_.length == 0)
      populateLayerGroup(base_map_defs, this.basemapGroup, this.rebuildLegend);
    else return;
    //populateLayerGroup(defaultLayers, this.testGroup, this.rebuildLegend);
  }

  rebuildLegend() {

   // this.something.renderPanel();


   //this.layerSwitcher.renderPanel();
  }
}
