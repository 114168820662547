<h2 mat-dialog-title>Forest Description Overlay</h2>
<mat-dialog-content class="mat-typography">
<section class="overlay-form">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="coordinates-form">
    <div class="coordinates-input-group">
      <mat-form-field class="coordinates-input">
        <mat-label>Min Longitude</mat-label>
        <input matInput formControlName="minLon" type="number">
      </mat-form-field>
      <mat-form-field class="coordinates-input">
        <mat-label>Min Latitude</mat-label>
        <input matInput formControlName="minLat" type="number">
      </mat-form-field>
    </div>
    <div class="coordinates-input-group">
      <mat-form-field class="coordinates-input">
        <mat-label>Max Longitude</mat-label>
        <input matInput formControlName="maxLon" type="number">
      </mat-form-field>
      <mat-form-field class="coordinates-input">
        <mat-label>Max Latitude</mat-label>
        <input matInput formControlName="maxLat" type="number">
      </mat-form-field>
    </div>
    <div class="submit-button-container">
      <button mat-raised-button color="primary" type="submit">Submit</button>
    </div>
  </form>
</section>
<section #map class="overlay-map_map">
</section>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
      close
    </button>
  </footer>
</mat-dialog-actions>
