import { AfterViewInit, Component, ElementRef, HostBinding, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { Company, CompanyService } from 'src/app/services/company.service';
import { CustomValidators } from 'src/app/_helpers/customValidators';

import OlMap from 'ol/Map';
import { ProjectionLike } from 'ol/proj';
import View from 'ol/View';
import * as olProj from 'ol/proj';
import { mapProj3857, mapProjEPSG } from 'src/app/_helpers/projection_setup';
import { base_map_defs } from 'src/app/_helpers/basemaps';
import { populateLayerGroup } from 'src/app/_helpers/LayerBuilder';
import LayerGroup from 'ol/layer/Group';
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { ConvertFeatureToGeoJson } from 'src/app/_helpers/transformations';
import TileLayer from 'ol/layer/Tile';
import { BingMaps } from 'ol/source';


@Component({
  selector: 'app-import-shape-file-dialog',
  templateUrl: './import-shape-file-dialog.component.html',
  styleUrls: ['./import-shape-file-dialog.component.css']
})
export class ImportShapeFileDialogComponent implements OnInit {

  coupeImportForm: UntypedFormGroup;

  companies: Array<Company> = [];
  user: User;

  proj : ProjectionLike | null = olProj.get(mapProj3857);
  baseMapGroup = new LayerGroup({
    layers: [],
    title: 'Background Maps',
    fold: 'open',
  } as any);

  companySub$: Subscription = new Subscription();
  userSub$: Subscription = new Subscription();
  @ViewChild('mapElementRef1', { static: true }) mapElementRef1: ElementRef;

  constructor(public dialogRef: MatDialogRef<ImportShapeFileDialogComponent>,@Inject(MAT_DIALOG_DATA) public feature:Feature<any>,private authService:AuthService, private companyService: CompanyService) {

    this.companySub$ = this.companyService.companies.subscribe(companies => {
      this.companies = companies
    });

    this.userSub$ = this.authService.user.subscribe(user => {
      this.user = user;
    });

   }


  ngOnInit(): void {
      this.dialogRef.disableClose = true;
      populateLayerGroup(base_map_defs, this.baseMapGroup, ()=>{});

      this.coupeImportForm = new UntypedFormGroup({
        'coupeCode': new UntypedFormControl(null, CustomValidators.required),
        'coupeName': new UntypedFormControl(null, CustomValidators.required),
        'treeSpecies': new UntypedFormControl(null, CustomValidators.required),
        'seedlot': new UntypedFormControl(null, CustomValidators.required),
        'targetStems': new UntypedFormControl(null, CustomValidators.required),
        'rowSpacing': new UntypedFormControl(null, CustomValidators.required),
        'interRowSpacing': new UntypedFormControl(null, CustomValidators.required),
        'nursery': new UntypedFormControl(null, CustomValidators.required),
        'hasNetting': new UntypedFormControl(null, CustomValidators.required),
        'hasFertilizer': new UntypedFormControl(null, CustomValidators.required),
        'company': new UntypedFormControl(null, CustomValidators.required),
    });

    this.coupeImportForm.get('company').setValue(this.user.companyID);


  }

  ngAfterViewInit(): void {

    var bingBaseMap =  new TileLayer({
      source:
      new BingMaps({
      key: "ApjDwuDN5ZojO5zSMU_CqVhOaB5OTRFp0uzIS2IYfHJDT1BTrfY8Lt6HvIQ30K1C",
      imagerySet: "RoadOnDemand"
    })});

    let map = new OlMap({
      layers: [
        bingBaseMap,
        new VectorLayer({source: new VectorSource({
          features: [this.feature]
        })})
      ],
      view: new View({
        center: [494095, 5343594],
        projection: this.proj == null ? null : this.proj,
        zoom: 5,
        maxZoom: 23,
      }),
      pixelRatio: window.devicePixelRatio,
      maxTilesLoading: 256,
      controls: [
      ],

    });
map.setTarget(this.mapElementRef1.nativeElement);

map.updateSize();
map.getView().fit(this.feature.getGeometry().getExtent(),{padding:[25,25,25,25]});
}


  onSubmit(){
    this.feature.setProperties(this.coupeImportForm.value);
    this.dialogRef.close(this.feature);
  }

}
