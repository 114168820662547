import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { spatialService } from 'src/app/services/spatial.service';
import SidebarEsk from 'src/app/_helpers/sidebar_esk';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorComponent } from '../shared/snackbar/error/error.component';
import { Feature } from 'ol';
import { ImportShapeFileDialogComponent } from '../shared/dialogs/import-shape-file-dialog/import-shape-file-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import { ConvertFeatureToGeoJson, ConvertGeoJsonToFeatureCollection } from 'src/app/_helpers/transformations';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';
import { Point } from 'ol/geom';
import { Select } from 'ol/interaction';

import * as condition from 'ol/events/condition';
import { AttributeOverlayComponent } from './tools/attribute-overlay/attribute-overlay.component';
import { SelectEvent } from 'ol/interaction/Select';
import { SpatialReferenceSelectorComponent } from '../shared/dialogs/spatial-reference-selector/spatial-reference-selector.component';
import { AuthService } from 'src/app/services/auth.service';
import { combineLatest, debounceTime, delay, fromEvent, map, startWith, Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/_helpers/customValidators';
import { saveAs } from 'file-saver';
import { ImportResponse, ImportService } from 'src/app/services/import.service';
import { boundingExtent } from 'ol/extent';
import { fromLonLat, transformExtent } from 'ol/proj';
import { createXYZ } from 'ol/tilegrid';
import { tile as tileStrategy } from 'ol/loadingstrategy';
import { DeviceService } from 'src/app/services/device.service';
import { CoupeService } from 'src/app/services/coupe.service';
import { Coupe } from '../main/coupes/coupes.component';
import { EditCoupeComponent } from '../shared/dialogs/coupe/edit-coupe/edit-coupe.component';
import { MatTableDataSource } from '@angular/material/table';
import { SuccessComponent } from '../shared/snackbar/success/success.component';
import { Fill, RegularShape, Stroke, Style } from 'ol/style';
import { CoupeCardComponent } from './coupe-card/coupe-card.component';
import { environment } from 'src/environments/environment';
import { Papa } from 'ngx-papaparse';
import WKT from 'ol/format/WKT';
import { VectorLayerExtended, WebGLPointsLayerExtended } from 'src/app/_helpers/VectorLayerExtensions';
import { AreaOfInterestDialogComponent } from 'src/app/shared/components/area-of-interest-dialog/area-of-interest-dialog.component';
import { log } from 'console';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
})
export class MapComponent implements OnInit, AfterViewInit {
  @ViewChild('mapElementRef', { static: true }) mapElementRef: ElementRef;

  @ViewChild('addAttributeOverlayHere', {
    static: false,
    read: ViewContainerRef,
  })
  attributeOverlayTarget: ViewContainerRef;
  private attributeComponentRef: ComponentRef<AttributeOverlayComponent>;


  @ViewChildren(CoupeCardComponent,) panes!: QueryList<CoupeCardComponent>;

  geoServerEndpoint: string = '';
  selectInteraction: Select;
  selectedFeatures: Array<Feature<any>> = [];

  treeVectorSource: VectorSource<Point>;
  treeVectorLayer: WebGLPointsLayer<any>;

  conflictedTreeVectorSource: VectorSource<Point>;
  conflictedTreeVectorLayer: VectorLayer<any>;

  userSub$: Subscription = new Subscription();
  user: User;
  uploadShapeFileForm: UntypedFormGroup;

  searchField: FormControl;

  production:boolean = false;


  filteredCoupes$;

  format = new WKT();

  filter = '';
  webGLTreePontStyle;
coupes: Array<Coupe> = [];

selectStyle = new Style({
  fill: new Fill({
    color: '#eeeeee',
  }),
  stroke: new Stroke({
    color: 'rgba(0,132, 255, 0.7)',
    width: 2,
  }),
});


hoveredCoupe: Feature<any>;

  constructor(
    public spatialService: spatialService,
    public dialog: MatDialog,
    private authService: AuthService,
    private deviceService: DeviceService,
    private coupeService: CoupeService,
    private snackBar: MatSnackBar,
    private papa: Papa
  ) {
    this.userSub$ = authService.user.subscribe((user) => {
      this.user = user;
    });

    this.production = environment.production;

    this.searchField = new FormControl('');

    this.treeVectorSource = new VectorSource<Point>({});
    this.conflictedTreeVectorSource = new VectorSource<Point>({});

    this.geoServerEndpoint = `${environment.geoServerURL}`;
  }

  async ngOnInit(): Promise<any> {



    const coupes$ = this.coupeService.getAll();

    const searchTerm$ = this.searchField.valueChanges.pipe(
      startWith(this.searchField.value),
    );

      this.filteredCoupes$ = combineLatest([coupes$,searchTerm$]).pipe(
        map(
          ([coupes,searchTerm])=>
          coupes.filter(
            (coupe:Coupe)=>
            searchTerm === '' ||
            coupe.coupeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            coupe.coupeCode.toLowerCase().includes(searchTerm.toLowerCase())
          )
         )
      )

    this.spatialService.map.setTarget(this.mapElementRef.nativeElement);
    this.spatialService.map.updateSize();

    this.uploadShapeFileForm = new UntypedFormGroup({
      file: new UntypedFormControl(null, CustomValidators.required),
    });

    this.deviceService.getAll().subscribe(devices => {
      let webGlColor: any = [
        'match',
        ['get', 'DeviceID']];

      devices.forEach(device => {
        webGlColor.push(device.id.toString(),device.color);
      });

      webGlColor.push('#006688')

      console.log('colors', webGlColor)

      this.webGLTreePontStyle = {
        symbol: {
          symbolType: 'circle',
          size: 8,
          color: webGlColor,
          opacity: 0.5,
        },
      };


      const crossStyle = new Style({
        image: new RegularShape({
          fill: new Fill({
            color: 'blue'
          }),
          stroke: new Stroke({
            color: 'black',
            width: 1
          }),
          points: 4,
          radius: 10,
          radius2: 0,
          angle: Math.PI / 4,
        })});

    this.treeVectorLayer = new WebGLPointsLayerExtended({
      source: this.treeVectorSource,
      style: this.webGLTreePontStyle,
      title: 'Trees',
    });

    this.conflictedTreeVectorLayer = new VectorLayerExtended({
      source: this.conflictedTreeVectorSource,
      style: crossStyle,
      title: 'Conflicted Tree Points',
      visible:false

    });

    this.spatialService.map.addLayer(this.conflictedTreeVectorLayer);
    this.spatialService.map.addLayer(this.treeVectorLayer);



    this.selectInteraction = new Select({
      multi: false,
      condition: condition.click,
      hitTolerance: 10,
    });

    this.selectInteraction.on('select', this.featureSelected.bind(this));

    this.spatialService.map.addInteraction(this.selectInteraction);

    });



// Define your bounding box for Tasmania in EPSG:4326
let boundingBox = [144.3532, -43.6455, 148.5053, -39.5796];

let adelaideBoundingBox = [139.5068, -38.2634 , 141.6175, -36.9472];


// Now you can convert from EPSG:4326 to EPSG:28355
let boundingBoxIn28355 = transformExtent(adelaideBoundingBox, 'EPSG:4326', 'EPSG:3857');

this.spatialService.map.getView().fit(boundingBoxIn28355);

    this.loadTrees();
    this.loadConflictedTrees();

    this.spatialService.addCoupesLayer();



  }

  test()
  {
    this.dialog.open(AreaOfInterestDialogComponent, {width: '95%', height: '95%'}).afterClosed().subscribe( result => {

    });
  }

  loadTrees()
  {
    this.treeVectorSource.clear();

    let features:Array<Feature<any>> = [];

    this.papa.parse(`${this.geoServerEndpoint}OFO-GeoTree/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=cite%3ATrees${this.filter}&srsname=EPSG:3857&outputFormat=csv`, {download: true, delimiter: ',',header:true, step:(result: {data:  {Altitude: string,CompanyID: string, CoupeID: string, DeviceID: string, FID: string, Geom: string, Heading: string, HorizontalAccuracy: string,Id: string, Lat: string, Lon:string, Pitch:string, Roll:string,Tilt:string,Utc:string, VerticalAccuracy:string }, errors: Array<any>, meta: {}}) => {



      let feature =  this.format.readFeature(result.data.Geom) as Feature<Point>;

      Object.keys(result.data).forEach(key => {
        if(key.toLowerCase() == 'geom')
          return;

        feature.set(key,result.data[key]);
      })

      features.push(feature);

      },complete: results => {
        this.treeVectorSource.addFeatures(features);

      }, worker: true})


  }


  loadConflictedTrees()
  {
    this.conflictedTreeVectorSource.clear();

    let features:Array<Feature<any>> = [];

    this.papa.parse(`${this.geoServerEndpoint}GeoTree/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=cite%3AConflictingTreePoints${this.filter}&srsname=EPSG:3857&outputFormat=csv`, {download: true, delimiter: ',',header:true, step:(result: {data:  {Altitude: string,CompanyID: string, CoupeID: string, DeviceID: string, FID: string, Geom: string, Heading: string, HorizontalAccuracy: string,Id: string, Lat: string, Lon:string, Pitch:string, Roll:string,Tilt:string,Utc:string, VerticalAccuracy:string }, errors: Array<any>, meta: {}}) => {



      let feature =  this.format.readFeature(result.data.Geom) as Feature<Point>;

      Object.keys(result.data).forEach(key => {
        if(key.toLowerCase() == 'geom')
          return;

        feature.set(key,result.data[key]);
      })

      features.push(feature);

      },complete: results => {
        this.conflictedTreeVectorSource.addFeatures(features);

      }, worker: true})


  }




  ngAfterViewInit(): void {
    this.panes.changes.subscribe((x: QueryList<CoupeCardComponent>) => {
      x.forEach(coupeCard => {
        fromEvent(coupeCard.elementRef.nativeElement, 'mouseenter').pipe(
        ).subscribe(() => {
          let a  = this.spatialService.coupesSource.forEachFeatureIntersectingExtent([coupeCard.coupe.extent.left, coupeCard.coupe.extent.top, coupeCard.coupe.extent.right, coupeCard.coupe.extent.bottom], (feature)=>{
            if(feature.getProperties()['CoupeID'] == coupeCard.coupe.id)
            {
              return feature;
            }
            else return undefined;
          })

          coupeCard.hovered = true;
          this.hoveredCoupe = a;
          a.setStyle(this.selectStyle);

        });

        fromEvent(coupeCard.elementRef.nativeElement, 'mouseleave').pipe(
          ).subscribe(() => {
            this.hoveredCoupe.setStyle(null)
            coupeCard.hovered = false;
          });
      })
  });
  }
  getBaseGroup() {
    return this.spatialService.basemapGroup;
  }

  shapeFileImported(features: Array<Feature<any>>) {
    const dialogRef = this.dialog.open(ImportShapeFileDialogComponent, {
      hasBackdrop: true,
      width: '80%',
      data:features[0]
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      let data = new FormData();
      if(result instanceof Feature<any>)
      {
        let geoJson = ConvertFeatureToGeoJson(result);
        console.log(geoJson);
        data.append('geoJson',ConvertFeatureToGeoJson(result));
        data.append('companyID',result.getProperties()['company']);
        this.coupeService.add(data).subscribe(response => {
          this.showSuccess("Shape File Successfully imported")
          this.coupeService.getAll().subscribe((coupes: Array<Coupe>) => {
            this.coupes = coupes;
            });
        })
      }

    });
  }

  treesImported(treeImportResponse: ImportResponse) {

    this.spatialService.map
      .getView()
      .fit([treeImportResponse.left, treeImportResponse.top, treeImportResponse.right, treeImportResponse.bottom]);

    this.spatialService.map.render();

    this.loadTrees();
    //this.loadConflictedTrees();


  }

  zoomToCoupe(coupe)
  {
    this.spatialService.map
    .getView()
    .fit([coupe.extent.left, coupe.extent.top, coupe.extent.right, coupe.extent.bottom]);
  }

  applyFilter(filter:string)
  {
    this.filter = filter;
    this.loadTrees();
  }

  updateCoupe(coupe:Coupe)
  {
    const dialogRef = this.dialog.open(EditCoupeComponent, {
      hasBackdrop: true,
      width: '80%',
      data: coupe,
    });

    dialogRef.afterClosed().subscribe(async (result) => {

      if(result != null && result != false)
      {
        this.coupeService.update(result).subscribe({
          next: (value) => {
            this.showSuccess("Coupe updated");
            this.coupeService.getAll().subscribe((coupes: Array<Coupe>) => {
            this.coupes = coupes;
            });
          }
        });

      }
    });
  }

  hoverCoupe(coupe:Coupe)
  {

  }

  unHoverCoupe(coupe:Coupe)
  {


  }

  showSuccess(message: string): void {
    this.snackBar.openFromComponent(SuccessComponent, {
      duration: 5 * 1000,
      data: message,
      panelClass: ['success-snackbar'],
    });
  }

  showError(message: string): void {
    this.snackBar.openFromComponent(ErrorComponent, {
      duration: 5 * 1000,
      data: message,
      panelClass: ['error-snackbar'],
    });
  }


  featureSelected(event: SelectEvent) {
    if (event.selected?.length <= 0) {
      this.attributeComponentRef.destroy();
      return;
    }
    if (event.deselected?.length > 0) {
      this.attributeComponentRef.destroy();
    }

    this.attributeComponentRef = this.attributeOverlayTarget.createComponent(
      AttributeOverlayComponent
    );

    console.log(this.selectInteraction.getLayer(event.selected[0]).get('title'));

    this.attributeComponentRef.instance.map = this.spatialService.map;
    this.attributeComponentRef.instance.selectedFeature = event.selected[0];
    this.attributeComponentRef.instance.attributeAliasMap = null;
    this.attributeComponentRef.instance.layerTitle = this.selectInteraction.getLayer(event.selected[0]).get('title');

    this.attributeComponentRef.instance.loadTrees.subscribe(result => {
      this.loadTrees();
      this.loadConflictedTrees();
    });
  }
}
