import * as olProj from 'ol/proj';

import {register} from 'ol/proj/proj4';
import proj4 from 'proj4';


proj4.defs("EPSG:28355","+proj=utm +zone=55 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
proj4.defs('EPSG:3857', '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext +no_defs');

register(proj4);


export var mapProjEPSG = "EPSG:28355"


var map_proj = olProj.get(mapProjEPSG);

if(!map_proj.getWorldExtent()){//Aus
    map_proj.setWorldExtent([112.85, -43.7, 153.69, -9.86]);
}

var projExt = olProj.transformExtent(map_proj.getWorldExtent(), olProj.get('EPSG:4326'), map_proj);

map_proj.setExtent(projExt);


export var mapProj3857 = 'EPSG:3857';

var map_proj2 = olProj.get(mapProjEPSG);

if(!map_proj2.getWorldExtent()){//Aus
  map_proj2.setWorldExtent([112.85, -43.7, 153.69, -9.86]);
}

var projExt = olProj.transformExtent(map_proj2.getWorldExtent(), olProj.get('EPSG:4326'), map_proj2);

map_proj2.setExtent(projExt);
